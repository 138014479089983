import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Box from "@mui/system/Box";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import BlockTitle from "@shared/components/contents/blockTitle/BlockTitle";
const BlockWithTitle = (props) => {
    const { id = "", image = null, title = "", justify = "flex-start", backgroundColor = "#FFFFFF", dynamicPart = null, ...others } = props;
    return (_jsxs(Box, { sx: {
            bgcolor: backgroundColor,
            mt: image ? 8 : 2,
            pt: image ? 0 : 2.5,
            px: 5,
        }, ...others, children: [_jsxs(Box, { sx: {
                    display: "flex",
                    justifyContent: justify,
                    px: 2.5,
                }, children: [image && (_jsx(Box, { sx: { mt: -5.625, height: "50%", width: "50%" }, children: _jsx(ImageContainer, { ratio: "", children: image }) })), title && (_jsx(Box, { sx: { ml: 1 }, children: _jsx(BlockTitle, { text: title }) }))] }), dynamicPart] }, id));
};
export default BlockWithTitle;
