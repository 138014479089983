import { theme } from "@docaposte-agility/da-design-system";
import { createTheme } from "@mui/material/styles";
import { attachmentStyled } from "./styles/attachmentStyled";
import { glossaryStyled } from "./styles/glossaryStyled";
import { infoBlockStyled } from "./styles/infoBlockStyled";
import { scrollableTabsStyled } from "./styles/scrollableTabsStyled";
import { searchBarStyled } from "./styles/searchBarStyled";
import { textStyled } from "./styles/textStyled";
import { timeLineStepStyled } from "./styles/timeLineStepStyled";
const overrideKey = "customization";
const primary = {
    light: "#1D23F5",
    main: "#0000FF",
    dark: "#0000FF",
    text: "#343434",
};
const secondary = {
    main: "#2A4161",
    light: "#d4e5ff",
    text: "#F3F3F3",
    btnBackground: "#5f5f5f33",
};
const border = "#ECECEF";
const containedPrimaryButton = {
    backgroundColor: primary.main,
    "&:hover": {
        backgroundColor: primary.dark,
    },
    "&:active": {
        backgroundColor: primary.dark,
    },
};
const containedSecondaryButton = {
    backgroundColor: secondary.btnBackground,
    color: secondary.main,
    "&:hover": {
        backgroundColor: secondary.light,
        color: secondary.main,
    },
    "&:active": {
        backgroundColor: secondary.light,
    },
};
const outlinedPrimaryButton = {
    color: primary.main,
    backgroundColor: "white",
    borderColor: primary.main,
    "&:hover": {
        color: "white",
        backgroundColor: primary.light,
        borderColor: primary.dark,
    },
    "&:active": {
        color: "white",
        backgroundColor: primary.light,
        borderColor: primary.dark,
    },
};
const outlinedSecondaryButton = {
    color: secondary.main,
    backgroundColor: "white",
    borderColor: secondary.main,
    "&:hover": {
        color: secondary.light,
        backgroundColor: "white",
        borderColor: secondary.light,
    },
    "&:active": {
        color: secondary.light,
        backgroundColor: "white",
        borderColor: secondary.light,
    },
};
const common = {
    consent: {
        wrap: {
            background: "white",
            borderColor: border,
        },
    },
};
export default createTheme(theme, {
    palette: {
        primary,
        secondary,
        border,
        common,
    },
    [overrideKey]: {
        Text: {
            text: {
                ...(theme.components?.MuiTypography?.styleOverrides?.root ||
                    {}),
                "& h2": theme.components?.MuiTypography?.styleOverrides?.h2,
                "& h3": theme.components?.MuiTypography?.styleOverrides?.h3,
                "& h4": theme.components?.MuiTypography?.styleOverrides?.h4,
                "& h5": theme.components?.MuiTypography?.styleOverrides?.h5,
                "& a": theme.components?.MuiLink,
            },
        },
        Attachment: {
            container: {
                backgroundColor: "#D1EBFF",
                "&:hover": {
                    backgroundColor: "#9cd3fd",
                },
            },
        },
        Accordion: {
            expandIcon: {
                color: primary.main,
            },
        },
    },
    components: {
        searchBarStyled: searchBarStyled,
        timeLineStepStyled: timeLineStepStyled,
        textStyled: textStyled,
        scrollableTabsStyled: scrollableTabsStyled,
        infoBlockStyled: infoBlockStyled,
        glossaryStyled: glossaryStyled,
        attachmentStyled: attachmentStyled,
        MuiLink: {
            styleOverrides: {
                root: {},
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    "&:hover": {
                        color: "white",
                    },
                    "&:active": {
                        color: "white",
                    },
                },
                sizeLarge: {
                    padding: "25px 24px",
                },
                containedPrimary: containedPrimaryButton,
                containedSecondary: containedSecondaryButton,
                outlinedPrimary: outlinedPrimaryButton,
                outlinedSecondary: outlinedSecondaryButton,
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: primary.light,
                },
            },
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: "md",
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    backgroundColor: secondary.light,
                    borderRadius: 7,
                    padding: 5,
                },
                underline: {
                    "&::before": {
                        border: "none",
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: "1.2rem",
                    minHeight: 26,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: "100%",
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    color: "#fff",
                    backgroundColor: primary.main,
                    minHeight: 40,
                    borderRadius: "10px",
                    "&$expanded": {
                        borderRadius: "10px 10px 0 0",
                    },
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    maxWidth: "auto",
                },
            },
        },
    },
});
